.pagination{
    margin-bottom: 10px;
    margin-left: 43%;
}
.pagination span{
    border: 1px solid white;
    padding:2px 8px;
    width: 30px;
    height: 30px;
    background-color: rgba(173, 186, 192, 0.795);
    cursor: pointer;

}

.pagination span.active{
    background-color: rgb(150, 150, 150);
}
