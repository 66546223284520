input::placeholder {
  font-size: 12px;
  color: #888;
}

/* Scrollbar styling for WebKit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* color of the tracking area, should match the background of the table */
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3161BE80;
  /* color of the scroll thumb */
  border-radius: 5px;
  /* roundness of the scroll thumb */
  border: 2px solid #f0f0f0;
  /* creates a little border around scroll thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #3160be;
  /* color of the scroll thumb when hovered over */
}