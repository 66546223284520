.vendor-main{
    padding: 15px;
}
.vendor-input{
    display: flex;
    gap: 15px;
    margin-left: 5%;
    margin-top: 100px;
    
}
.input-vendor{
    height:35px;
    border-radius: 5px;
    border: 1px solid rgb(165, 165, 165);
    
    
}
.vendor-table{
    text-align: center;
}
.vr-table{
    margin-top:10px ;
    margin-left:5% ;
    margin-right:5% ;
}


.del-popup {
    position: fixed;
    top: 28%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
  }
 
  .del-popup p {
    margin-bottom: 10px;
  }
 
  .del-popup button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
    background: #007bff;
    color: #fff;
    border: none;
    padding: 8px 20px;
    font-size: 16px;
    border-radius: 4px;
  }