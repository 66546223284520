body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 0px;
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c7cee2;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c7cee2;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/assets/fonts/static/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-thin';
  src: url('../public/assets/fonts/static/Montserrat-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-light';
  src: url('../public/assets/fonts/static/Montserrat-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

.margin-icon {
  position: absolute;
  cursor: pointer;
  margin-left: -28px;
  top: 200px;
  transform: rotate(270deg);
}

.mar-icon {
  width: 40px;
  color: #0064be
}

.margin-line {
  border-right: 4px solid #3161BE;
  overflow-y: auto;
  width: 100%;
  margin-top: 65px;
  margin-left: -16px;
  position: relative;
  height: 91.7vh
}

.view3d-wrapper {
  width: 100%;
  height: 100%;
  background-color: #c8c6d2;
  border-radius: 5px;
}

.image-upload-loading:before {
  content: '';
  background: #0000002b;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 1000;
}

.image-upload-loading:after {
  content: '';
  position: fixed;
  width: 60px;
  height: 60px;
  z-index: 999999;
  background-image: url('../public/assets/ZZ5H.gif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}